import MainContent from "./MainContent.jsx";

import logoBlack from "../../../assets/images/logo-black.png";
import logoWhite from "../../../assets/images/logo-white.png";
import { Link, Navigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useEffect, useRef, useState } from "react";
const MainLayout = () => {
  const handleEarlyAccess = () => {
    // localStorage.clear();
    Navigate("/get-early-access");
  };

  const ref = useRef(null);
  const location = useLocation();
  // useEffect(() => {
  //   ref.current.continuousStart()
  //   setTimeout(() => {
  //     ref.current.complete()
  //   }, 100)
  // }, [location])

  const [isVisible, setIsVisible] = useState(false);

  // Button will be visible after user scrolls down 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll effect
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <LoadingBar color="#305ff0" ref={ref} shadow={true} height={3} />
      <div className="sb-nav-fixed overall-body">
        {/* <TheHeader /> */}
        <div className="scroll-to-top">
          {isVisible && (
            <button onClick={scrollToTop} className="btn btn-primary rounded-pill scroll-rounded-btn">
              <i className="fi fi-bs-angle-up l-h-1 v-align-middle"></i>
            </button>
          )}
        </div>
        <div
          id="layoutSidenav"
        // className={collapsed ? "sidebar-minimized" : "un-minimized"}
        >
          <div id="layoutSidenav_content" className={"create-vch-header"}>
            <nav
              className="navbar navbar-toggleable-md navbar-expand-lg navbar-dark kitaab-navbar d-none"
              id="kitaab-navbar"
            >
              <div className="container-fluid mobile-container-fluid">
                <a className="navbar-brand" href="/">
                  <img src={logoBlack} className="img-fluid" width="138" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mynavbar"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fi fi-br-bars-staggered text-color-3 l-h-1 v-align-middle" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="mynavbar">
                  <ul className="navbar-nav me-auto me-32 ms-32">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/pricing" className="nav-link">
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Partner with us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Try Demo Mode
                      </Link>
                    </li>
                  </ul>
                  <form className="d-flex mobile-top-12 mobile-header-btns">
                    <button
                      className="btn btn-outline-primary btn-padding me-16 btn-padding font-size-16 b-r-8"
                      type="button"
                    >
                      Book a Demo
                    </button>
                    <Link to="/get-early-access"

                      className="btn btn-primary btn-padding btn-padding font-size-16 b-r-8"
                    >
                      Get Early Access
                    </Link>
                  </form>
                </div>
              </div>
            </nav>

            <main className="user-guide-pattern">
              <div className="">
                <MainContent />
              </div>
            </main>

            <footer className="bg-dark text-light d-none">
              <div className="container p-4 mobile-p-56 mobile-p-s-e-0">
                <div className="row align-items-center">
                  <div className="col-12 text-center">
                    <div className="">
                      <p className="font-size-12 mb-1">2024 &copy; Kitaabh. All Rights Reserved. </p>
                    </div>
                  </div>

                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
