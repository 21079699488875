import './App.css';
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import MainLayout from './components/layout/mainlayout/MainLayout';
import routes from './routes';
import React, { useEffect, useRef } from 'react';
import Login from './Login.jsx';
import LoadingBar from 'react-top-loading-bar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  const router = createHashRouter(
    createRoutesFromElements(
      <Route path="/">
        {/* <Route path="forgot-password" element={<ForgotPassword />} /> */}
        <Route path="login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          {routes.map((route, idx) => (
            <Route key={idx} path={route.path} element={<route.component />} />
          ))}
        </Route>
      </Route>
    )
  );

  return (

    <div className="App">
      <React.Suspense fallback={loading}>
      <ToastContainer />
        <RouterProvider router={router} />
      </React.Suspense>
    </div>
  );
}

export default App;
