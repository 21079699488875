import React from "react";


const HomePage = React.lazy(() => import("./components/layout/home/Home"));
// const UserGuideLandingView = React.lazy(() => import("./components/layout/home/UserGuideView"));
// const UserGuideCreate = React.lazy(() => import("./components/layout/create-user-guide/CreateUserGuide"));
// const UserGuideList = React.lazy(() => import("./components/layout/create-user-guide/UserGuideList"));
const UserInvite = React.lazy(() => import("./components/layout/user-invite/UserInvite"));



const routes = [

  { path: "/", component: HomePage },
  // { path: "/user-guide-view", component: UserGuideLandingView },
  // { path: "/user-guide-create", component: UserGuideCreate },
  // { path: "/user-guide-list", component: UserGuideList },
  { path: "/accept-invitation", component: UserInvite },

];

export default routes;
